<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="114">
        <v-row dense v-if="loading">
          <v-col cols="12">
            <div class="text-caption text-center">Loading vehicles...</div>
          </v-col>
          <v-col cols="12" class="mb-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row dense v-else>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              :value="vehicleCalendar.requestingLocationFilter"
              :items="locations"
              item-text="name"
              item-value="id"
              label="Requesting Location"
              outlined
              clearable
              dense
              @change="setFilter($event, 'requestingLocationFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="vehicleCalendar.vehicleLocationFilter"
              :items="vehicleLocations"
              item-text="name"
              item-value="id"
              label="Vehicle Location"
              outlined
              clearable
              dense
              @change="setFilter($event, 'vehicleLocationFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="vehicleCalendar.vehicleTypeFilter"
              :items="vehicleTypes"
              item-text="name"
              item-value="id"
              label="Vehicle Type"
              outlined
              clearable
              dense
              @change="setFilter($event, 'vehicleTypeFilter')"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-select
              :value="vehicleCalendar.tripTypeFilter"
              :items="tripTypes"
              item-text="name"
              item-value="id"
              label="Trip Type"
              outlined
              clearable
              dense
              @change="setFilter($event, 'tripTypeFilter')"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-select
              :value="vehicleCalendar.zoneFilter"
              :items="zones"
              label="Zone"
              outlined
              clearable
              dense
              @change="setFilter($event, 'zoneFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="vehicleCalendar.vehicleFilter"
              :items="vehicles"
              item-text="name"
              item-value="id"
              label="Vehicle"
              outlined
              clearable
              dense
              @change="setFilter($event, 'vehicleFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="vehicleCalendar.vehicleOwnerFilter"
              :items="vehicleOwnerItems"
              item-text="name"
              item-value="email"
              label="Vehicle Owner"
              outlined
              clearable
              dense
              @change="setFilter($event, 'vehicleOwnerFilter')"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <v-toolbar flat class="tbar">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" class="mx-2 button-chip">
                <v-icon left class="mr-2 blue--text">mdi-information</v-icon>
                <span class="blue--text">Color Codes</span>
              </v-btn>
            </template>
            <div>
              <div class="font-weight-bold green--text text--darken-1">Driver Accepted</div>
              <div class="font-weight-bold blue--text text--darken-2">Driver Assigned (Pending Action)</div>
              <div class="font-weight-bold orange--text text--darken-3">No Driver Assigned</div>
              <div class="font-weight-bold red--text text-accent-2">Driver Declined</div>
            </div>
          </v-tooltip>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[vehicleCalendar.type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleTypeChange('day')">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('week')">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('month')">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('4day')">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="950">
        <v-calendar
          ref="calendar"
          :value="vehicleCalendar.focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="vehicleCalendar.type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card v-if="selectedEvent.tripData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                <div class="d-flex justify-space-between mt-2">
                  <span>{{ selectedEvent.vehicle }}</span>
                  <div class="">
                    <span outlined class="right">{{ selectedEvent.id ? status(selectedEvent.id).display : '' }}</span>
                  </div>
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4 mb-2" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr v-for="(value, propertyName) in selectedEvent.tripData" :key="propertyName + value">
                      <td>{{ propertyName }}</td>
                      <td>{{ value }}</td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData && canViewTrip(selectedEvent.tripRequestId)"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.tripRequestId}?calendar=true&assignment=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="selectedEvent.dateData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                {{ selectedEvent.dateData.type == 'blocked' ? 'Blocked Date' : 'Special Date' }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr>
                      <td>Description</td>
                      <td>
                        {{ selectedEvent.dateData.description }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr>
                      <td>Trip Types Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr v-if="selectedEvent.dateData.tripEventIds">
                      <td>Trip Events Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripEventIds.map((e) => tripEventsById[e].name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.tripRequestId}?calendar=true&assignment=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { groupBy } from 'lodash';
import { add, sub, getMonth, getYear } from 'date-fns';
import { getVehicleLocation, todayString, months, calculateDateShift, getAssignmentVehicleType } from '@/util';
import { getAssignmentStatusObj } from '@/util/assignment';
import { ASSIGNMENT_STATUS } from '@/shared/common';

export default {
  props: {
    blockedDateEvents: Array,
    specialDateEvents: Array,
    loading: Boolean,
  },
  data: () => ({
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.$refs.calendar.move(0);
  },
  computed: {
    ...mapGetters('calendar', ['calendarAssignments', 'calendarAssignmentsById']),
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('tripRequest', ['tripRequests', 'tripRequestsById']),
    ...mapGetters('vehicleType', ['vehicleTypesById', 'vehicleTypes']),
    ...mapGetters('tripType', ['tripTypesById', 'tripTypes']),
    ...mapGetters('tripEvent', ['tripEventsById', 'tripEvents']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('location', ['locationsById', 'locations', 'vehicleLocations', 'zones']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicle', ['vehiclesById', 'vehicles']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators', 'blockedDates', 'specialDates', 'driverConfig']),
    ...mapGetters('user', ['usersById', 'usersByEmail', 'vehicleOwners']),
    ...mapGetters('app', ['vehicleCalendar']),
    events() {
      let filteredAssignments = [...this.calendarAssignments].filter((e) => e.vehicle || e.vehicleId);
      if (this.vehicleCalendar.requestingLocationFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => e.locationId == this.vehicleCalendar.requestingLocationFilter
        );
      if (this.vehicleCalendar.vehicleLocationFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => getVehicleLocation(e.vehicleId, true)?.id == this.vehicleCalendar.vehicleLocationFilter
        );
      if (this.vehicleCalendar.vehicleTypeFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => e.vehicleTypeId == this.vehicleCalendar.vehicleTypeFilter
        );
      if (this.vehicleCalendar.tripTypeFilter)
        filteredAssignments = filteredAssignments.filter((e) => e.tripTypeId == this.vehicleCalendar.tripTypeFilter);
      if (this.vehicleCalendar.zoneFilter)
        filteredAssignments = filteredAssignments.filter((e) => this.zone(e) == this.vehicleCalendar.zoneFilter);
      if (this.vehicleCalendar.vehicleFilter)
        filteredAssignments = filteredAssignments.filter((e) => e.vehicleId == this.vehicleCalendar.vehicleFilter);
      if (this.vehicleCalendar.vehicleOwnerFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => this.getVehicleOwner(e, true) == this.vehicleCalendar.vehicleOwnerFilter
        );

      filteredAssignments = filteredAssignments
        .filter(
          (e) =>
            e.status === ASSIGNMENT_STATUS.NONE ||
            e.status === ASSIGNMENT_STATUS.PENDING ||
            e.status === ASSIGNMENT_STATUS.SELF_ACCEPTED ||
            e.status === ASSIGNMENT_STATUS.ADMIN_ACCEPTED
        )
        .map((assignment) => {
          const location = this.locationsById[assignment.locationId];
          const tripType = this.tripTypesById[assignment.tripTypeId];
          const vehicleType = getAssignmentVehicleType(assignment);
          const destination = this.destinationsById[assignment.destinationId];
          const driver = this.getDriver(assignment);
          const vehicleOwner = this.getVehicleOwner(assignment);

          // Check if any required data is undefined
          if (!location || !tripType || !vehicleType || !destination || !driver || !vehicleOwner) {
            return null; // Skip this assignment
          }

          return {
            name: `${this.getVehicle(assignment)} \u2023 Trip #${assignment.tripRequestId} \u2023 ${
              location.abbr
            } \u2023 ${tripType.name}`,
            id: assignment.id,
            vehicle: this.getVehicle(assignment),
            tripRequestId: assignment.tripRequestId,
            allDay: false,
            start: this.formatDateForCalendar(assignment.leaveDate, assignment.leaveTime),
            end: this.formatDateForCalendar(assignment.returnDate, assignment.returnTime),
            timed: true,
            color: this.status(assignment.id).color,
            tripData: {
              'Trip #': assignment.tripRequestId,
              'Trip Type / Event(s)': `${tripType.name} / ${assignment.tripEventIds
                .map((e) => this.tripEventsById[e].name)
                .join(', ')}`,
              'Vehicle Type': vehicleType?.name,
              'Vehicle Location': getVehicleLocation(assignment.vehicleId),
              Driver: driver,
              'Requesting Location': `${location.name} (${location.code})`,
              Destination: destination.name,
              Teacher: `${assignment.teacherName} (Phone: ${assignment.teacherPhone})`,
              'Requested By':
                assignment.submittedUser && this.usersById[assignment.submittedUser]
                  ? this.usersById[assignment.submittedUser].displayName
                  : '-',
              Zone: this.zone(assignment),
              Owner: vehicleOwner,
              'Vehicle Pickup': this.readableDate(assignment.vehPickupDate, assignment.vehPickupTime),
              'Vehicle Return': this.readableDate(assignment.vehReturnDate, assignment.vehReturnTime),
            },
          };
        })
        .filter((assignment) => assignment !== null);

      return [...this.blockedDateEvents, ...this.specialDateEvents, ...filteredAssignments];
    },

    vehicleOwnerItems() {
      const byUser = groupBy(this.vehicleOwners, 'userEmail');
      return Object.keys(byUser).map((e) => ({
        email: e,
        name: this.usersByEmail[e] ? this.usersByEmail[e].displayName || e : e,
      }));
    },
    title() {
      const currentFocus = this.vehicleCalendar.focus ? this.vehicleCalendar.focus : todayString();
      return `${months[getMonth(new Date(currentFocus))]} ${getYear(new Date(currentFocus))}`;
    },
    getAbbreviation(text) {
      if (typeof text != 'string' || !text) {
        return '';
      }
      const abb = text
        .match(/[\p{Alpha}\p{Nd}]+/gu)
        .reduce((previous, next) => previous + (+next === 0 || parseInt(next) ? parseInt(next) : next[0] || ''), '')
        .toUpperCase();
      return abb;
    },
  },
  methods: {
    ...mapMutations('app', ['setVehicleCalendar']),
    formatDateForCalendar(date, time) {
      const [year, month, day] = date.split('-');
      const [hours, minutes] = time.split(':');
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    },
    readableDate(date, time) {
      const [year, month, day] = date.split('-');
      const d = new Date(`${month}-${day}-${year}`).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const s = time.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${d} @ ${hour}:${minute} ${ampm}`;
    },
    zone(assignment) {
      if (this.tripRequestConfig && this.tripRequestConfig.other) {
        if (this.tripRequestConfig.other.determineZoneBy == 'request')
          return this.locationsById[assignment.locationId].zone;
        if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[assignment.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation].zone;
        }
      }
      return '-';
    },
    status(assignmentId) {
      const assignment = this.calendarAssignmentsById[assignmentId];
      return getAssignmentStatusObj(assignment);
    },
    canViewTrip(tripRequestId) {
      return this.tripRequestsById[tripRequestId] && this.tripRequestsById[tripRequestId].permissions.canView;
    },
    getDriver(item) {
      if (item.driver) return item.driver;
      else if (item.driverId)
        return `${this.driversById[item.driverId].firstName} ${this.driversById[item.driverId].lastName}`;
      else return '-';
    },
    getVehicle(item) {
      if (item.vehicle) return item.vehicle;
      else if (item.vehicleId) return this.vehiclesById[item.vehicleId].name;
      else return '-';
    },
    getVehicleOwner(item, returnEmail) {
      const owner = this.vehicleOwners.find((e) => e.locationId == item.assignmentLocationId);
      if (!owner) return returnEmail ? '' : '-';
      if (returnEmail) return owner.userEmail;
      return owner.displayName ? `${owner.displayName} (${owner.userEmail})` : owner.userEmail;
    },
    viewDay({ date }) {
      this.setVehicleCalendar({ ...this.vehicleCalendar, type: 'day', focus: date });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.setVehicleCalendar({ ...this.vehicleCalendar, focus: '' });
    },
    prev() {
      const currentFocus = this.vehicleCalendar.focus ? this.vehicleCalendar.focus : todayString();
      const focus = sub(new Date(currentFocus), calculateDateShift(this.vehicleCalendar.type));
      this.setVehicleCalendar({ ...this.vehicleCalendar, focus });
    },
    next() {
      const currentFocus = this.vehicleCalendar.focus ? this.vehicleCalendar.focus : todayString();
      const focus = add(new Date(currentFocus), calculateDateShift(this.vehicleCalendar.type));
      this.setVehicleCalendar({ ...this.vehicleCalendar, focus });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    handleTypeChange(type) {
      this.setVehicleCalendar({ ...this.vehicleCalendar, type });
    },
    updateRange() {},
    setFilter(val, filter) {
      const obj = { ...this.vehicleCalendar };
      obj[filter] = val;
      this.setVehicleCalendar(obj);
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}
.right {
  float: right;
}
.tbar {
  margin-top: -24px;
}
.v-tooltip__content {
  background-color: #fff !important;
  border: 1px solid #c9c6c6 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  opacity: 1 !important;
}
</style>
