<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="114">
        <v-row dense v-if="loading">
          <v-col cols="12">
            <div class="text-caption text-center">Loading drivers...</div>
          </v-col>
          <v-col cols="12" class="mb-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row dense v-else>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              :value="driverCalendar.requestingLocationFilter"
              :items="locations"
              item-text="name"
              item-value="id"
              label="Requesting Location"
              outlined
              clearable
              dense
              @change="setFilter($event, 'requestingLocationFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="driverCalendar.vehicleTypeFilter"
              :items="vehicleTypes"
              item-text="name"
              item-value="id"
              label="Vehicle Type"
              outlined
              clearable
              dense
              @change="setFilter($event, 'vehicleTypeFilter')"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-select
              :value="driverCalendar.tripTypeFilter"
              :items="tripTypes"
              item-text="name"
              item-value="id"
              label="Trip Type"
              outlined
              clearable
              dense
              @change="setFilter($event, 'tripTypeFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="driverCalendar.driverFilter"
              :items="drivers"
              :item-text="getDriverItemName"
              item-value="id"
              label="Driver"
              outlined
              clearable
              dense
              @change="setFilter($event, 'driverFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="driverCalendar.driverEmailFilter"
              :items="driverEmailItems"
              label="Driver Email"
              outlined
              clearable
              dense
              @change="setFilter($event, 'driverEmailFilter')"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-select
              :value="driverCalendar.zoneFilter"
              :items="zones"
              label="Zone"
              outlined
              clearable
              dense
              @change="setFilter($event, 'zoneFilter')"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <v-toolbar flat class="tbar">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" class="mx-2 button-chip">
                <v-icon left class="mr-2 blue--text">mdi-information</v-icon>
                <span class="blue--text">Color Codes</span>
              </v-btn>
            </template>
            <div>
              <div class="font-weight-bold green--text text--darken-1">Driver Accepted</div>
              <div class="font-weight-bold blue--text text--darken-2">Driver Assigned (Pending Action)</div>
              <div class="font-weight-bold orange--text text--darken-3">No Driver Assigned</div>
              <div class="font-weight-bold red--text text-accent-2">Driver Declined</div>
            </div>
          </v-tooltip>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[driverCalendar.type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleTypeChange('day')">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('week')">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('month')">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('4day')">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="950">
        <v-calendar
          ref="calendar"
          :value="driverCalendar.focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="driverCalendar.type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card v-if="selectedEvent.tripData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                {{ selectedEvent.name }}
                <span class="right">{{ selectedEvent.id ? status(selectedEvent.id).display : '' }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4 mb-2" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr v-for="(value, propertyName) in selectedEvent.tripData" :key="propertyName + value">
                      <td>{{ propertyName }}</td>
                      <td>{{ value }}</td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData && canViewTrip(selectedEvent.tripRequestId)"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.tripRequestId}?calendar=true&assignment=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="selectedEvent.dateData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                {{ selectedEvent.dateData.type == 'blocked' ? 'Blocked Date' : 'Special Date' }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr>
                      <td>Description</td>
                      <td>
                        {{ selectedEvent.dateData.description }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr>
                      <td>Trip Types Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr v-if="selectedEvent.dateData.tripEventIds">
                      <td>Trip Events Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripEventIds.map((e) => tripEventsById[e].name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.tripRequestId}?calendar=true&assignment=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { add, sub, getMonth, getYear } from 'date-fns';
import { todayString, months, calculateDateShift, getAssignmentVehicleType } from '@/util';
import { getAssignmentStatusObj } from '@/util/assignment';
import { ASSIGNMENT_STATUS } from '@/shared/common';

export default {
  props: {
    blockedDateEvents: Array,
    specialDateEvents: Array,
    loading: Boolean,
  },
  data: () => ({
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.$refs.calendar.move(0);
  },
  computed: {
    ...mapGetters('calendar', ['calendarAssignments', 'calendarAssignmentsById']),
    ...mapGetters('assignment', ['assignments']),
    ...mapGetters('tripRequest', ['tripRequests', 'tripRequestsById']),
    ...mapGetters('vehicleType', ['vehicleTypesById', 'vehicleTypes']),
    ...mapGetters('tripType', ['tripTypesById', 'tripTypes']),
    ...mapGetters('tripEvent', ['tripEventsById', 'tripEvents']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('location', ['locationsById', 'locations', 'vehicleLocations', 'zones']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('driver', ['driversById', 'drivers']),
    ...mapGetters('vehicle', ['vehiclesById', 'vehicles']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators', 'blockedDates', 'specialDates', 'driverConfig']),
    ...mapGetters('user', ['usersById', 'usersByEmail', 'vehicleOwners']),
    ...mapGetters('app', ['driverCalendar']),
    events() {
      let filteredAssignments = [...this.calendarAssignments].filter((e) => e.driver || e.driverId);
      if (this.driverCalendar.requestingLocationFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => e.locationId == this.driverCalendar.requestingLocationFilter
        );
      if (this.driverCalendar.vehicleTypeFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => e.vehicleTypeId == this.driverCalendar.vehicleTypeFilter
        );
      if (this.driverCalendar.tripTypeFilter)
        filteredAssignments = filteredAssignments.filter((e) => e.tripTypeId == this.driverCalendar.tripTypeFilter);
      if (this.driverCalendar.driverFilter)
        filteredAssignments = filteredAssignments.filter((e) => e.driverId == this.driverCalendar.driverFilter);
      if (this.driverCalendar.driverEmailFilter)
        filteredAssignments = filteredAssignments.filter(
          (e) => this.getDriverEmail(e) == this.driverCalendar.driverEmailFilter
        );
      if (this.driverCalendar.zoneFilter)
        filteredAssignments = filteredAssignments.filter((e) => this.zone(e) == this.driverCalendar.zoneFilter);

      filteredAssignments = filteredAssignments
        .filter(
          (e) =>
            e.status === ASSIGNMENT_STATUS.NONE ||
            e.status === ASSIGNMENT_STATUS.PENDING ||
            e.status === ASSIGNMENT_STATUS.SELF_ACCEPTED ||
            e.status === ASSIGNMENT_STATUS.ADMIN_ACCEPTED
        )
        .map((assignment) => {
          const vehicleType = getAssignmentVehicleType(assignment);
          const location = this.locationsById[assignment.locationId];
          const tripType = this.tripTypesById[assignment.tripTypeId];
          const destination = this.destinationsById[assignment.destinationId];

          // Check if any required data is undefined
          if (!vehicleType || !location || !tripType || !destination) {
            return null; // Skip this assignment
          }

          return {
            name: `${this.getDriver(assignment)} \u2023 ${this.getVehicle(assignment) || 'No Vehicle'}`,
            id: assignment.id,
            tripRequestId: assignment.tripRequestId,
            allDay: false,
            start: this.formatDateForCalendar(assignment.leaveDate, assignment.leaveTime),
            end: this.formatDateForCalendar(assignment.returnDate, assignment.returnTime),
            timed: true,
            color: this.status(assignment.id).color,
            tripData: {
              'Trip #': assignment.tripRequestId,
              Vehicle: this.getVehicle(assignment) || '-',
              'Vehicle Type': vehicleType?.name,
              'Requesting Location': `${location.name} (${location.code})`,
              'Vehicle Pickup': this.readableDate(assignment.vehPickupDate, assignment.vehPickupTime),
              'Vehicle Return': this.readableDate(assignment.vehReturnDate, assignment.vehReturnTime),
              'Trip Type / Event(s)': `${tripType.name} / ${assignment.tripEventIds
                .map((e) => this.tripEventsById[e].name)
                .join(', ')}`,
              Destination: destination.name,
              Teacher: assignment.teacherName,
              'Requested By':
                assignment.submittedUser && this.usersById[assignment.submittedUser]
                  ? this.usersById[assignment.submittedUser].displayName
                  : '-',
              'Driver Email': this.getDriverEmail(assignment) || '-',
              'Driver Phone': this.getDriverPhone(assignment),
              Zone: this.zone(assignment),
            },
          };
        })
        .filter((assignment) => assignment !== null);

      return [...this.blockedDateEvents, ...this.specialDateEvents, ...filteredAssignments];
    },

    driverEmailItems() {
      return this.calendarAssignments
        .filter((e) => e.driverEmail || (e.driverId && this.driversById[e.driverId].email))
        .map((e) => e.driverEmail || this.driversById[e.driverId].email)
        .sort((a, b) => a.localeCompare(b));
    },
    title() {
      const currentFocus = this.driverCalendar.focus ? this.driverCalendar.focus : todayString();
      return `${months[getMonth(new Date(currentFocus))]} ${getYear(new Date(currentFocus))}`;
    },
  },
  methods: {
    ...mapMutations('app', ['setDriverCalendar']),
    formatDateForCalendar(date, time) {
      const [year, month, day] = date.split('-');
      const [hours, minutes] = time.split(':');
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    },
    readableDate(date, time) {
      const [year, month, day] = date.split('-');
      const d = new Date(`${month}-${day}-${year}`).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const s = time.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${d} @ ${hour}:${minute} ${ampm}`;
    },
    zone(assignment) {
      if (this.tripRequestConfig && this.tripRequestConfig.other) {
        if (this.tripRequestConfig.other.determineZoneBy == 'request')
          return this.locationsById[assignment.locationId].zone;
        if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[assignment.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation].zone;
        }
      }
      return '-';
    },
    status(assignmentId) {
      const assignment = this.calendarAssignmentsById[assignmentId];
      return getAssignmentStatusObj(assignment);
    },
    canViewTrip(tripRequestId) {
      return this.tripRequestsById[tripRequestId] && this.tripRequestsById[tripRequestId].permissions.canView;
    },
    getDriver(item) {
      if (item.driver) return item.driver;
      else if (item.driverId)
        return `${this.driversById[item.driverId].firstName} ${this.driversById[item.driverId].lastName}`;
      else return '-';
    },
    getDriverEmail(item) {
      if (item.driverEmail) return item.driverEmail;
      else if (item.driverId) return this.driversById[item.driverId].email;
      else return '-';
    },
    getDriverPhone(item) {
      if (item.driverId && this.driversById[item.driverId].contacts.length) {
        const primary = this.driversById[item.driverId].contacts.find((e) => e.isPrimary);
        if (primary && primary.phones.length) return primary.phones[0].phone;
      }
      return '-';
    },
    getDriverItemName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    getVehicle(item) {
      if (item.vehicle) return item.vehicle;
      else if (item.vehicleId) return this.vehiclesById[item.vehicleId].name;
      else return '';
    },
    getVehicleOwner(item, returnEmail) {
      const owner = this.vehicleOwners.find((e) => e.locationId == item.assignmentLocationId);
      if (!owner) return returnEmail ? '' : '-';
      if (returnEmail) return owner.userEmail;
      return owner.displayName ? `${owner.displayName} (${owner.userEmail})` : owner.userEmail;
    },
    viewDay({ date }) {
      this.setDriverCalendar({ ...this.driverCalendar, type: 'day', focus: date });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.setDriverCalendar({ ...this.driverCalendar, focus: '' });
    },
    prev() {
      const currentFocus = this.driverCalendar.focus ? this.driverCalendar.focus : todayString();
      const focus = sub(new Date(currentFocus), calculateDateShift(this.driverCalendar.type));
      this.setDriverCalendar({ ...this.driverCalendar, focus });
    },
    next() {
      const currentFocus = this.driverCalendar.focus ? this.driverCalendar.focus : todayString();
      const focus = add(new Date(currentFocus), calculateDateShift(this.driverCalendar.type));
      this.setDriverCalendar({ ...this.driverCalendar, focus });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    handleTypeChange(type) {
      this.setDriverCalendar({ ...this.driverCalendar, type });
    },
    updateRange() {},
    setFilter(val, filter) {
      const obj = { ...this.driverCalendar };
      obj[filter] = val;
      this.setDriverCalendar(obj);
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}
.right {
  float: right;
}
.tbar {
  margin-top: -24px;
}
.v-tooltip__content {
  background-color: #fff !important;
  border: 1px solid #c9c6c6 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  opacity: 1 !important;
}
</style>
